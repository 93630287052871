var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-app-bar',{staticClass:"main-appbar",attrs:{"flat":"","color":"transparent"}},[_c('app-bar-nav-icon'),(_vm.loading)?_c('v-skeleton-loader',{attrs:{"type":"chip","transition":"fade-transition"}}):_c('v-toolbar-title',{staticClass:"headline font-weight-bold"},[_vm._v("Users ("+_vm._s(_vm.listMeta.total)+")")]),_c('v-spacer'),_c('v-row',{directives:[{name:"show",rawName:"v-show",value:(_vm.showSearch),expression:"showSearch"}],staticStyle:{"max-width":"300px"},attrs:{"align":"center"}},[_c('v-text-field',{ref:"searchField",staticClass:"search",attrs:{"flat":"","hide-details":"","solo":"","rounded":"","clearable":"","background-color":"grey lighten-3","label":"Search name, email or phone number"},on:{"input":_vm.search},model:{value:(_vm.filter.search),callback:function ($$v) {_vm.$set(_vm.filter, "search", $$v)},expression:"filter.search"}})],1),(_vm.loading)?_c('v-skeleton-loader',{attrs:{"type":"actions","transition":"fade-transition"}}):[_c('v-select',{staticClass:"ml-10",staticStyle:{"max-width":"200px"},attrs:{"hide-details":"","dense":"","item-text":"label","item-value":"value","items":_vm.filterBy},on:{"change":_vm.filterUser},model:{value:(_vm.defaultFilter),callback:function ($$v) {_vm.defaultFilter=$$v},expression:"defaultFilter"}}),_c('v-btn',{staticClass:"ml-2",attrs:{"depressed":"","color":this.status ? 'primary' : ''},on:{"click":function($event){_vm.sortOrder = true
          _vm.filterUserBTN()}}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.arrowUp))])],1),_c('v-btn',{staticClass:"ml-2",attrs:{"depressed":"","color":this.status === false ? 'primary' : ''},on:{"click":function($event){_vm.sortOrder = false
          _vm.filterUserBTN()}}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.arrowDown))])],1),_c('v-btn',{staticClass:"ml-5",attrs:{"icon":""},on:{"click":_vm.showSearchField}},[_c('img',{attrs:{"src":require("@/assets/icons/search.svg"),"alt":"","height":"17.7px"}})]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-1",attrs:{"color":"#383d3f","icon":""},on:{"click":_vm.exportList}},on),[_c('v-icon',[_vm._v(_vm._s(_vm.icons.export))])],1)]}}])},[_c('span',[_vm._v("Export to Excel")])])]],2),_c('div',{directives:[{name:"infinite-scroll",rawName:"v-infinite-scroll",value:(_vm.loadMore),expression:"loadMore"}],staticClass:"mt-12 mx-4",attrs:{"id":"user-list","infinite-scroll-disabled":_vm.loading,"infinite-scroll-distance":"100"}},[_c('v-simple-table',[_c('thead',[_c('tr',[_c('th',{staticClass:"text-center pa-0",attrs:{"colspan":"2"}},[_vm._v("Name")]),_c('th',{staticClass:"text-left"},[_vm._v("Email")]),_c('th',{staticClass:"text-left",staticStyle:{"width":"100px"}},[_vm._v("Phone number")]),_c('th',{staticClass:"text-left",staticStyle:{"width":"100px"}},[_vm._v("Status")]),_c('th',{staticClass:"text-left",staticStyle:{"width":"50px"}},[_vm._v("ID Verified")]),_c('th',{staticClass:"text-left",staticStyle:{"width":"100px"}},[_vm._v("Added date")])])]),_c('tbody',[_vm._l((_vm.users),function(user){return _c('tr',{key:user.id,ref:'data-' + user.id,refInFor:true,staticClass:"clickable",attrs:{"id":user.id ? 'data-' + user.id : 'data'},on:{"click":function($event){_vm.$router.push({
              name: 'user.details',
              params: { id: user.id },
              query: {
                sort:
                  _vm.filterData.sort === null
                    ? 'full_name'
                    : _vm.filterData.sort.toString(),
                page:
                  _vm.filterData.page === null ? '1' : _vm.filterData.page.toString(),
                listId: 'data-' + user.id,
              },
            })}}},[_c('td',{attrs:{"colspan":"2"}},[_c('user-avatar',{staticClass:"mr-4",attrs:{"avatar":user.avatar,"size":"40"}}),_vm._v(" "+_vm._s(user.full_name)+" ")],1),_c('td',[_vm._v(_vm._s(user.email || 'N/A'))]),_c('td',[_vm._v(" "+_vm._s(user.phone_number ? '+' + user.phone_number : 'N/A')+" ")]),_c('td',[(!user.blocked_at && !user.deleted_at)?_c('p',{staticClass:"green--text"},[_vm._v(" Active ")]):(user.blocked_at && !user.deleted_at)?_c('p',{staticClass:"red--text"},[_vm._v(" Disabled ")]):(user.deleted_at)?_c('p',{staticClass:"red--text"},[_vm._v(" Deleted Account ")]):_vm._e()]),_c('td',{staticClass:"text-center"},[(
                user.id_verification_status === _vm.IdVerificationStatus.VERIFIED
              )?_c('small',{staticClass:"green--text"},[_vm._v("VERIFIED")]):(
                user.id_verification_status ===
                _vm.IdVerificationStatus.UNVERIFIED
              )?_c('small',{staticClass:"red--text"},[_vm._v("UNVERIFIED")]):(
                user.id_verification_status ==
                _vm.IdVerificationStatus.IN_PROGRESS
              )?_c('small',{staticClass:"warning--text"},[_vm._v("IN PROGRESS")]):(
                user.id_verification_status == _vm.IdVerificationStatus.PENDING
              )?_c('small',{staticClass:"warning--text"},[_vm._v("PENDING")]):(
                user.id_verification_status ===
                _vm.IdVerificationStatus.LOCKED_OUT
              )?_c('small',{staticClass:"red--text"},[_vm._v("LOCKED OUT")]):_vm._e()]),_c('td',{staticClass:"white_space"},[_c('small',[_vm._v(_vm._s(user.addedDate))])])])}),(_vm.loading)?_vm._l((10),function(index){return _c('tr',{key:index + '-skeleton'},[_c('td',{staticClass:"py-3 pl-0",attrs:{"width":"5%"}},[_c('v-skeleton-loader',{staticClass:"avatar-loading--36",attrs:{"type":"avatar","transition":"fade-transition"}})],1),_c('td',{staticClass:"pl-0"},[_c('v-skeleton-loader',{attrs:{"type":"list-item","transition":"fade-transition"}})],1),_c('td',{staticClass:"pl-0"},[_c('v-skeleton-loader',{attrs:{"type":"list-item","transition":"fade-transition"}})],1),_c('td',{staticClass:"pl-0"},[_c('v-skeleton-loader',{attrs:{"type":"list-item","transition":"fade-transition"}})],1),_c('td',{staticClass:"pl-0"},[_c('v-skeleton-loader',{attrs:{"type":"list-item","transition":"fade-transition"}})],1),_c('td',{staticClass:"pl-0"},[_c('v-skeleton-loader',{attrs:{"type":"list-item","transition":"fade-transition"}})],1)])}):_vm._e()],2)]),(!(_vm.users.length > 0) && !_vm.loading)?_c('div',{staticClass:"d-flex justify-center mt-3"},[_c('p',[_vm._v("No match found.")])]):_vm._e()],1),_c('v-snackbar',{attrs:{"color":_vm.snackbar.color,"right":""},model:{value:(_vm.snackbar.show),callback:function ($$v) {_vm.$set(_vm.snackbar, "show", $$v)},expression:"snackbar.show"}},[_vm._v(" "+_vm._s(_vm.snackbar.message)+" "),_c('v-btn',{attrs:{"color":"white","text":""},on:{"click":function($event){_vm.snackbar.show = false}}},[_vm._v("Ok")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }