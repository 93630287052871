<template>
  <div>
    <v-app-bar flat color="transparent" class="main-appbar">
      <app-bar-nav-icon />
      <v-skeleton-loader
        type="chip"
        transition="fade-transition"
        v-if="loading"
      ></v-skeleton-loader>

      <v-toolbar-title v-else class="headline font-weight-bold">
        Categories ({{ categories.length }})
      </v-toolbar-title>
      <v-btn
        color="primary"
        class="ml-5"
        :to="{ name: 'category.edit', params: { id: 'create' } }"
        >Create</v-btn
      >

      <v-spacer></v-spacer>
      <v-row v-show="showSearch" align="center" style="max-width: 300px">
        <v-text-field
          class="search mr-5"
          ref="searchField"
          flat
          hide-details
          solo
          rounded
          clearable
          background-color="grey lighten-3"
          label="Search label"
          v-model="filter.search"
        ></v-text-field>
      </v-row>

      <v-skeleton-loader
        type="actions"
        transition="fade-transition"
        v-if="loading"
      ></v-skeleton-loader>

      <template v-else>
        <v-btn
          depressed
          class="ml-2"
          :color="sortOrder ? 'primary' : ''"
          @click="
            sortOrder = true
            filterCategoryBTN()
          "
        >
          <v-icon>{{ icons.arrowUp }}</v-icon>
        </v-btn>
        <v-btn
          depressed
          class="ml-2"
          :color="!sortOrder ? 'primary' : ''"
          @click="
            sortOrder = false
            filterCategoryBTN()
          "
        >
          <v-icon>{{ icons.arrowDown }}</v-icon>
        </v-btn>

        <v-btn class="ml-5" icon @click="showSearchField">
          <img src="@/assets/icons/search.svg" alt height="17.7px" />
        </v-btn>
      </template>
    </v-app-bar>

    <div class="mt-12 mx-4" id="category-list">
      <v-simple-table>
        <thead>
          <tr>
            <th width="20%" colspan="2" class="text-left pa-0">Label</th>
            <th width="20%" colspan="2" class="text-left pa-0">Published</th>
            <th width="20%" colspan="2" class="text-left pa-0">Featured</th>
            <th width="20%" class="text-left">Sub-Categories</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="category in filteredCategories"
            :key="category.id"
            class="clickable"
            @click="
              $router.push({
                name: 'category.edit',
                params: { id: category.id },
                query: {
                  sort:
                    filterData.sort === null
                      ? 'label'
                      : filterData.sort.toString(),
                },
              })
            "
          >
            <td colspan="2">
              {{ category.label }}
            </td>
            <td colspan="2">
              <v-icon v-if="category.is_published">{{ icons.check }}</v-icon>
            </td>
            <td colspan="2">
              <v-icon v-if="category.is_featured">{{ icons.check }}</v-icon>
            </td>
            <td>{{ concatSubcategoryLabels(category.subcategories) }}</td>
          </tr>

          <template v-if="loading">
            <tr v-for="index in 10" :key="index + '-skeleton'">
              <td class="py-3 pl-0" width="5%">
                <v-skeleton-loader
                  class="avatar-loading--36"
                  type="avatar"
                  transition="fade-transition"
                />
              </td>
              <td class="pl-0">
                <v-skeleton-loader
                  type="list-item"
                  transition="fade-transition"
                />
              </td>
            </tr>
          </template>
        </tbody>
      </v-simple-table>

      <div
        class="d-flex justify-center mt-3"
        v-if="!(categories.length > 0) && !loading"
      >
        <p>No match found.</p>
      </div>
    </div>

    <v-snackbar v-model="snackbar.show" :color="snackbar.color" right>
      {{ snackbar.message }}
      <v-btn color="white" text @click="snackbar.show = false">Ok</v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import {
  mdiDotsVertical,
  mdiUnfoldMoreHorizontal,
  mdiMagnify,
  mdiAccountCircleOutline,
  mdiChevronRight,
  mdiFileExcel,
  mdiArrowUp,
  mdiArrowDown,
  mdiCheck,
} from '@mdi/js'
import ControlsMixin from '@/utils/mixins/Controls'
import AppBarNavIcon from '@/layouts/shared/AppBarNavIcon'
import infiniteScroll from 'vue-infinite-scroll'

export default {
  name: 'CategoriesPage',

  mixins: [ControlsMixin],

  directives: {
    infiniteScroll,
  },

  components: {
    AppBarNavIcon,
  },

  data() {
    return {
      loading: false,
      showSearch: false,
      showProfile: false,
      selectedProfile: null,
      filter: {
        search: '',
      },
      icons: {
        settings: mdiDotsVertical,
        sort: mdiUnfoldMoreHorizontal,
        search: mdiMagnify,
        view: mdiAccountCircleOutline,
        arrow: mdiChevronRight,
        export: mdiFileExcel,
        arrowUp: mdiArrowUp,
        arrowDown: mdiArrowDown,
        check: mdiCheck,
      },
      snackbar: {
        show: false,
        message: null,
        color: null,
      },
      defaultFilter: 'label',
      sortOrder: true,
      filterBy: [
        {
          value: 'label',
          label: 'label',
        },
      ],
      filterData: {
        sort: '',
      },
    }
  },

  created() {
    this.clearCategories()
    this.fetchCategories(1)
  },

  computed: {
    ...mapState({
      categories: (state) => state.category.list,
    }),
    filteredCategories() {
      if (!this.filter.search || this.filter.search.trim() === '') {
        return this.categories
      }

      return this.categories.filter((c) =>
        c.label.toLowerCase().includes(this.filter.search.toLowerCase())
      )
    },
  },

  methods: {
    ...mapActions({
      getCategories: 'category/getCategories',
    }),

    ...mapMutations({
      clearCategories: 'category/clearCategoryList',
    }),

    pageChanged(page) {
      this.fetchCategories(page)
    },

    filterCategory() {
      this.$router.push({
        name: this.$route.name,
        query: {
          sort: this.filterData.sort,
        },
      })
      this.clearCategories()
      this.fetchCategories()
    },
    filterCategoryBTN() {
      this.clearCategories()
      this.fetchCategories()
    },

    async fetchCategories() {
      let params
      if (this.loading) return
      if (JSON.stringify(this.$route.query) === '{}') {
        params = {
          sort: this.sortOrder
            ? `${this.defaultFilter}`
            : `-${this.defaultFilter}`,
        }
      } else {
        params = {
          sort: this.sortOrder
            ? `${this.$route.query.sort}`
            : `-${this.$route.query.sort}`,
        }
      }

      this.loading = true
      sessionStorage.setItem('categoryFilter', JSON.stringify(params))
      await this.getCategories(params)
      this.loading = false
      const data = sessionStorage.getItem('categoryFilter')
      this.filterData = JSON.parse(data)
    },

    showProfileDialog(profile) {
      this.selectedProfile = profile
      this.showProfile = true
    },

    showSnackbar(message, color) {
      this.snackbar.message = message
      this.snackbar.color = color
      this.snackbar.show = true
    },
    async showSearchField() {
      this.showSearch = !this.showSearch
      await this.$nextTick()
      this.$refs.searchField.$refs.input.focus()
    },
    concatSubcategoryLabels(subcategories) {
      return subcategories.map((c) => c.label).join(', ')
    },
  },

  updated() {
    this.$router
      .replace({
        name: this.$route.name,
        query: {
          ...this.$route.query,
          sort: this.$route.query.sort.toString().replace('-', ' ').trim(),
          page: parseInt(this.$route.query.page),
        },
      })
      .catch((err) => {
        if (err.name !== 'NavigationDuplicated') {
          throw err
        }
      })
    this.$route.query.sort.toString().replace('-', ' ')
  },
}
</script>
