export const UNVERIFIED = 'unverified'
export const VERIFIED = 'verified'
export const IN_PROGRESS = 'in_progress'
export const PENDING = 'pending'
export const LOCKED_OUT = 'locked_out'

export const IdVerificationLabels = {
  [UNVERIFIED]: 'Unverified',
  [VERIFIED]: 'Verified',
  [IN_PROGRESS]: 'In_Progress',
  [PENDING]: 'Pending',
  [LOCKED_OUT]: 'Locked_Out',
}

const IdVerificationStatus = {
  UNVERIFIED,
  VERIFIED,
  IN_PROGRESS,
  PENDING,
  LOCKED_OUT,
}

export default IdVerificationStatus
