<template>
  <div class="py-8">
    <v-app-bar flat color="transparent">
      <app-bar-nav-icon />
      <v-toolbar-title class="breadcrumbs d-flex align-center">
        <v-icon class="text-light-blue">{{ icons.arrowLeft }}</v-icon>
        <router-link
          class="text-decoration--none"
          :to="{ name: 'categories', query: this.$route.query }"
          >Categories</router-link
        >
      </v-toolbar-title>
    </v-app-bar>

    <CategoryForm />
  </div>
</template>

<script>
import { mdiAlertCircleOutline, mdiCheck, mdiChevronLeft } from '@mdi/js'
import CategoryForm from '@/views/Home/Categories/components/CategoryForm'
import AppBarNavIcon from '@/layouts/shared/AppBarNavIcon.vue'

export default {
  components: { CategoryForm, AppBarNavIcon },
  data() {
    return {
      icons: {
        arrowLeft: mdiChevronLeft,
        check: mdiCheck,
        alert: mdiAlertCircleOutline,
      },
    }
  },
}
</script>
