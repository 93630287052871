var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-app-bar',{staticClass:"main-appbar",attrs:{"flat":"","color":"transparent"}},[_c('app-bar-nav-icon'),(_vm.loading)?_c('v-skeleton-loader',{attrs:{"type":"chip","transition":"fade-transition"}}):_c('v-toolbar-title',{staticClass:"headline font-weight-bold"},[_vm._v(" Categories ("+_vm._s(_vm.categories.length)+") ")]),_c('v-btn',{staticClass:"ml-5",attrs:{"color":"primary","to":{ name: 'category.edit', params: { id: 'create' } }}},[_vm._v("Create")]),_c('v-spacer'),_c('v-row',{directives:[{name:"show",rawName:"v-show",value:(_vm.showSearch),expression:"showSearch"}],staticStyle:{"max-width":"300px"},attrs:{"align":"center"}},[_c('v-text-field',{ref:"searchField",staticClass:"search mr-5",attrs:{"flat":"","hide-details":"","solo":"","rounded":"","clearable":"","background-color":"grey lighten-3","label":"Search label"},model:{value:(_vm.filter.search),callback:function ($$v) {_vm.$set(_vm.filter, "search", $$v)},expression:"filter.search"}})],1),(_vm.loading)?_c('v-skeleton-loader',{attrs:{"type":"actions","transition":"fade-transition"}}):[_c('v-btn',{staticClass:"ml-2",attrs:{"depressed":"","color":_vm.sortOrder ? 'primary' : ''},on:{"click":function($event){_vm.sortOrder = true
          _vm.filterCategoryBTN()}}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.arrowUp))])],1),_c('v-btn',{staticClass:"ml-2",attrs:{"depressed":"","color":!_vm.sortOrder ? 'primary' : ''},on:{"click":function($event){_vm.sortOrder = false
          _vm.filterCategoryBTN()}}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.arrowDown))])],1),_c('v-btn',{staticClass:"ml-5",attrs:{"icon":""},on:{"click":_vm.showSearchField}},[_c('img',{attrs:{"src":require("@/assets/icons/search.svg"),"alt":"","height":"17.7px"}})])]],2),_c('div',{staticClass:"mt-12 mx-4",attrs:{"id":"category-list"}},[_c('v-simple-table',[_c('thead',[_c('tr',[_c('th',{staticClass:"text-left pa-0",attrs:{"width":"20%","colspan":"2"}},[_vm._v("Label")]),_c('th',{staticClass:"text-left pa-0",attrs:{"width":"20%","colspan":"2"}},[_vm._v("Published")]),_c('th',{staticClass:"text-left pa-0",attrs:{"width":"20%","colspan":"2"}},[_vm._v("Featured")]),_c('th',{staticClass:"text-left",attrs:{"width":"20%"}},[_vm._v("Sub-Categories")])])]),_c('tbody',[_vm._l((_vm.filteredCategories),function(category){return _c('tr',{key:category.id,staticClass:"clickable",on:{"click":function($event){_vm.$router.push({
              name: 'category.edit',
              params: { id: category.id },
              query: {
                sort:
                  _vm.filterData.sort === null
                    ? 'label'
                    : _vm.filterData.sort.toString(),
              },
            })}}},[_c('td',{attrs:{"colspan":"2"}},[_vm._v(" "+_vm._s(category.label)+" ")]),_c('td',{attrs:{"colspan":"2"}},[(category.is_published)?_c('v-icon',[_vm._v(_vm._s(_vm.icons.check))]):_vm._e()],1),_c('td',{attrs:{"colspan":"2"}},[(category.is_featured)?_c('v-icon',[_vm._v(_vm._s(_vm.icons.check))]):_vm._e()],1),_c('td',[_vm._v(_vm._s(_vm.concatSubcategoryLabels(category.subcategories)))])])}),(_vm.loading)?_vm._l((10),function(index){return _c('tr',{key:index + '-skeleton'},[_c('td',{staticClass:"py-3 pl-0",attrs:{"width":"5%"}},[_c('v-skeleton-loader',{staticClass:"avatar-loading--36",attrs:{"type":"avatar","transition":"fade-transition"}})],1),_c('td',{staticClass:"pl-0"},[_c('v-skeleton-loader',{attrs:{"type":"list-item","transition":"fade-transition"}})],1)])}):_vm._e()],2)]),(!(_vm.categories.length > 0) && !_vm.loading)?_c('div',{staticClass:"d-flex justify-center mt-3"},[_c('p',[_vm._v("No match found.")])]):_vm._e()],1),_c('v-snackbar',{attrs:{"color":_vm.snackbar.color,"right":""},model:{value:(_vm.snackbar.show),callback:function ($$v) {_vm.$set(_vm.snackbar, "show", $$v)},expression:"snackbar.show"}},[_vm._v(" "+_vm._s(_vm.snackbar.message)+" "),_c('v-btn',{attrs:{"color":"white","text":""},on:{"click":function($event){_vm.snackbar.show = false}}},[_vm._v("Ok")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }